import { defineStyleConfig } from '@chakra-ui/react'

const CardStyle = defineStyleConfig({
    baseStyle: {
        container: {
            rounded: "2xl",
            overflow: "hidden",
            position: "relative",
        },
        header: {
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 6,
            paddingBottom: 0,
        },
        body: {
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
        },
        footer: {
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 8,
        }
    },
    variants: {
        standardCard: {
            container: {
                background: "white",
                borderWidth: 1,
                borderColor: 'brand.themeDarkGrey'
            }
        },
        activeUser: {
            container: {
                background: "white",
                borderWidth: 1,
                borderColor: 'brand.themeMediumBlue',                
            },           
        },
        disabledUser: {
            container: {
                background: "white",
                borderWidth: 1,
                borderColor: 'brand.themeDarkGrey',                
            },
            header: {
                color: 'brand.themeDarkGrey'
            },
            body: {
                color: 'brand.themeDarkGrey'                
            }
        },
        loginScreenCard: {
            container: {
                borderWidth: 1,
                borderColor: 'brand.themeDarkGrey',
                rounded: "xl",
                background: "white",
            },
            header: {
                position: "relative",
                paddingTop: 8,
                paddingLeft: 8,
                paddingRight: 8,
            },
            body: {
                position: "relative",
                paddingTop: 4,
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 4,
            },
            footer: {
                position: "relative",
                paddingTop: 4,
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 12,
            }
        },
    },
    defaultProps: {
        variant: 'standardCard',
    },
})

export default CardStyle