import { FormControl, FormErrorMessage, FormLabel, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Textarea } from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";

export type HospitalityInputProps = {
    // value: string;
    // onChange: (arg: string) => void;
    // onBlur: (arg: string) => void;
    id: string,
    label: string,
    placeholder: string,
    defaultValue: any,
    errorMessage: string | undefined | null,
    registerProps: UseFormRegisterReturn<string>,
    optional?: boolean | undefined | null
    type?: string | undefined | null
};

export const HospitalityFormControl = ({ id, label, placeholder, defaultValue, errorMessage, registerProps, optional, type }: HospitalityInputProps) => {
    const isInvalid = !!errorMessage;
    return (
        <FormControl isInvalid={isInvalid}>
            <FormLabel htmlFor={id}>{label}{!!optional && <small>&nbsp;(optional)</small>}</FormLabel>
            {type && type === 'number'
                ? <NumberInput defaultValue={defaultValue ?? 1} min={1} max={999}>
                    <NumberInputField
                        id={id}
                        placeholder={placeholder}
                        {...registerProps}
                    />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                : type && type === 'textarea'
                    ? <Textarea
                        id={id}
                        placeholder={placeholder}
                        defaultValue={defaultValue ?? ''}
                        {...registerProps}
                    />
                    : <Input
                        id={id}
                        placeholder={placeholder}
                        defaultValue={defaultValue ?? ''}
                        {...registerProps}
                    />
            }
            <FormErrorMessage>
                {!!errorMessage && errorMessage}
            </FormErrorMessage>
        </FormControl>
    );
}
