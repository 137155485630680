import { defineStyleConfig } from '@chakra-ui/react'

const ButtonStyle = defineStyleConfig({
  baseStyle: {
    rounded: "md",
    fontWeight: "normal",
  },
  variants: {
    primaryButton: {
      color: "white",
      background: "brand.themeGold",
      backgroundColor: "brand.themeGold",
      textDecoration: "none",
      _hover: {
        background: "brand.themeBlue",
        backgroundColor: "brand.themeBlue",
        textDecoration: "none",
      },
      _disabled: {
        background: "brand.themeDarkGrey",
        backgroundColor: "brand.themeDarkGrey",
      }
    },
    redButton: {
      color: "white",
      background: "brand.themeMediumRed",
      backgroundColor: "brand.themeMediumRed",
      textDecoration: "none",
      _hover: {
        background: "brand.themeDarkRed",
        backgroundColor: "brand.themeDarkRed",
        textDecoration: "none",
      },
      _disabled: {
        background: "brand.themeLightRed",
        backgroundColor: "brand.themeLightRed",
      }
    },
    redButtonOutline: {
      background: "brand.themeWhite",
      backgroundColor: "brand.themeWhite",
      textDecoration: "none",
      borderColor: "brand.themeMediumRed",
      borderWidth: 1,
      textColor: "brand.themeMediumRed",
      _hover: {
        background: "brand.themeDarkRed",
        backgroundColor: "brand.themeDarkRed",
        textDecoration: "none",
        textColor: "brand.themeWhite",
      },
      _disabled: {
        borderColor: "brand.themeLightRed",
        textColor: "brand.themeLightRed",
      }
    },
  },
  defaultProps: {
    variant: 'primaryButton',
  },
})

export default ButtonStyle