import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { defineStyleConfig, extendTheme } from "@chakra-ui/react";
import ButtonStyle from "./buttonStyles";
import CardStyle from "./cardStyles";

const Form: ComponentStyleConfig = {
  parts: ['container'],
  baseStyle: {
    container: {
      marginY: '20px',
      label: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    }
  }
};

const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '21px',
  }
}

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: '600'
  }
}

const Link: ComponentStyleConfig = {
  baseStyle: {
    color: "brand.themeGold"
  }
}

const theme = extendTheme({
  components: {
    Button: ButtonStyle,
    Card: CardStyle,
    Form,
    Text,
    Heading,
    Link
  },
  colors: {
    brand: {
      themeDarkBlue: "#0F78B2",
      themeMediumBlue: "#1891D4",
      themeDarkRed: "#BC0707",
      themeLightRed: "#FF7070",
      themeMediumRed: "#E40000",
      themeYellow: "#FFD54E",
      themeWhite: "#ffffff",
      themeDarkGrey: "#D9D9D9",
      themeTransparentGrey: 'rgba(0, 0, 0, 0.25)',
      themeGold: "#c39256",
      themeBlue: "#202b49"
    }
  },
  fonts: {
    // body: `'Open Sans', sans-serif`,
    // heading: `'Raleway', sans-serif`,
    // strong: `'Raleway', sans-serif`,
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
    strong: "Montserrat, sans-serif"
  },
  sizes: {
    container: {
      maxWidth: 1440,
    }
  },
  shadows: {
    footerShadow: '0 0 4px 0px rgba(0, 0, 0, 0.1)'
  }
})

export default theme;