import theme from "./assets/styles/theme";
import * as React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import {
  ChakraProvider,
  Box,
  Text,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  VisuallyHiddenInput,
  Flex,
  Container,
  Heading,
  Badge,
  Avatar,
  Link,
  Stack,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneNumberInput } from "./PhoneNumberInput"
import { isValidNumberForRegion, isValidPhoneNumber } from "libphonenumber-js";
import { IFormInput } from "./types"
import axios, { AxiosResponse } from 'axios';
import { HospitalityFormControl } from "./components/hospitalityFormControl";

// const CATALYST_HOST = 'https://content-heron-viable.ngrok-free.app';
// const CATALYST_HOST = 'http://localhost:3000'; window.location.hostname;
const CATALYST_HOST = window.location.hostname.startsWith('localhost')
  ? `http://${window.location.host}`
  : `https://${window.location.host}`;

// export type Shape<Fields extends Record<string, unknown>> = {
//   [Key in keyof Fields]: yup.AnySchema<Fields[Key]>;
// };

export type FormSchema<T> = {
  [K in keyof Required<T>]: yup.Schema<T[K]>;
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape<FormSchema<IFormInput>>({
  id: yup.string().optional(),
  Client_Code: yup.string().required(),
  // title: yup.mixed<TitleEnum>().oneOf(Object.values(TitleEnum)).optional(),
  Title: yup.string().optional(),
  First_Name: yup.string().required('First Name is required'),
  Last_Name: yup.string().required('Last Name is required'),
  //// @ts-expect-error ts(2339)
  // Phone: yup.string()
  //   .when('Phone', ([phone], schema) => {
  //     return !!phone
  //       ? schema.test(
  //         'is-valid-phone-number',
  //         'Invalid phone number',
  //         (value) => {
  //           try {
  //             return isValidPhoneNumber(phone);
  //           } catch (error) {
  //             return false;
  //           }
  //         }
  //       ).required()
  //       : schema.optional();
  //   }),
  Phone: yup.string()
    .when('Phone', ([phone], schema) => {
      return schema.test(
        'is-valid-phone-number',
        'Invalid phone number',
        (value: any) => {
          console.log("validating phone number => ", { value, phone });
          try {
            let validPhone = isValidPhoneNumber(value.trim());
            if (!validPhone && value.startsWith("0")) {
              validPhone = isValidPhoneNumber(value.trim().substring(1))
            }
            return validPhone;
          }
          catch (error) {
            return false
          }
        }).required();
    }),
  Email: yup.string().email().required('Email is required'),
  Company: yup.string().optional(),
  Description: yup.string().optional(),
  Quantity: yup.number().required(),
  //  Address
  Street: yup.string().required(),
  City: yup.string().required(),
  State: yup.string().optional(),
  Zip_Code: yup.string().required(),
  Country: yup.string().required(),
  Special_Requests: yup.string().optional(),
},
  [
    ['Phone', 'Phone']  //  Avoid circular dependency when validating
  ]
);

const renderResponseBox = (submitStatus: string, owner: Owner | undefined) => {

  if (!!!owner) {
    owner = {
      name: "Sales",
      email: "sales@hospitalitycentre.co.uk",
      image_link: "https://yt3.googleusercontent.com/XPXmkS4YZBQHtWSZtolq2DPBLAoApClXhN1btZdI1WGxwOsi4mrxt3wZnl_SHEyINS3P0AUG2g=s176-c-k-c0x00ffffff-no-rj",
      id: "0",
    }
  }

  var message = '';
  switch (submitStatus) {
    case "SUCCESS":
      message = 'Thanks for submitting this form, a member of our sales team will be in touch with you shortly. The salesperson who will be in touch is:';
      break;
    case "FAILURE":
      message = 'Oops, something went wrong submitting this form. Please refresh this page and try again. If the error persists, please contact:';
      break;
    case "ALREADY_SUBMITTED":
      message = 'You have already submitted this form. If you have futher queries or amendments that you wish to make, please contact:';
      break;
    case "ERROR_LOADING":
      message = 'There was an error loading this form. Please refresh this page and try again. If the error persists, please contact:';
      break;
    case "NOT_FOUND":
      message = 'There was no form found matching the information you have specified. The form may have already been submitted or expired. If you believe this is in error, please contact:';
      break;
    case "PLEASE_WAIT":
      message = 'We are unable to serve the form at the moment, please refresh the page and try again. If the error persists, please contact:';
      break;
    case "NO_CLIENT_CODE":
      message = "You have not provided a client code. You cannot access this form until a salesperson has instructed you to do so. Please contact your salesperson to get access to complete this form";
      break;
    case "CLIENT_CODE_DOES_NOT_MATCH":
      message = "The code you have provided is invalid. Please contact your salesperson to get access to complete this form.";
      break;
    case "CLIENT_CODE_EXPIRED":
      message = "Your code has expired. Please contact your salesperson to get access to complete this form.";
      break;
    default:
      message = 'Something unknown has occurred. Please contact the site administrator.';
      break;
  }

  return <Box mt={'5'} mb={'5'} p='5' borderWidth='1px' rounded='md'>
    <Stack direction={'column'}>
      <Center>
        <Text>{message}</Text>
      </Center>
      <Flex
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
        wrap={"wrap"}>
        <Flex
          shrink={3}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Avatar src={owner.image_link} />
          <Text>{owner.name}</Text>
        </Flex>
        <Link
          isExternal={true}
          fontSize={'sm'}
          size={'xs'}
          href={`mailto:${owner.email}`}>{owner.email}</Link>
      </Flex>
    </Stack>
  </Box >;
}

const renderResponseBadge = (submitStatus: string) => {
  let colorScheme = 'purple';
  switch (submitStatus) {
    case "SUCCESS":
      colorScheme = 'green';
      break;
    case "FAILURE":
    case "ERROR_LOADING":
    case "NOT_FOUND":
      colorScheme = 'red';
      break;
    case "ALREADY_SUBMITTED":
    case "PLEASE_WAIT":
      colorScheme = 'yellow';
      break;
    default:
      colorScheme = 'purple';
      break;
  }

  return <Badge colorScheme={colorScheme}>{submitStatus}</Badge>
}

type Owner = {
  email: string;
  id: string;
  name: string;
  image_link: string;
}

export const App = () => {
  const [submitStatus, setSubmitStatus] = React.useState<string>();
  const [product, setProduct] = React.useState<{
    Product_Name: string,
    Product_Code: string,
    Event: string,
    Venue: string,
    Package: string,
    Event_Date: string,
    Friendly_Name: string,
  }>();

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const code = queryParams.get("code");

  //  Use this code to fetch data from the client function
  console.log({ code, queryParams });

  const [initialValues, setInitialValues] = React.useState<IFormInput>();
  const [owner, setOwner] = React.useState<Owner>()

  React.useEffect(() => {
    if (!!code && !!id) {
      axios.get(`${CATALYST_HOST}/server/clientfunction/client/GetLeadByIdAndCode?id=${id}&code=${code}&timestamp=${new Date().getTime()}`, {
        headers: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': `${CATALYST_HOST}`,

          //"Content-Type": "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "must-revalidate,no-cache,no-store,max-age=1",
          'Pragma': 'no-cache',
          'Expires': '1',
        },
      })
        .then((response) => {
          console.log("axios response => ", response);
          if (response.status === 200 && !!response && !!response.data && !!response.data.id) {
            setInitialValues({
              id: response.data.id,
              Client_Code: response?.data?.Client_Code,
              Email: response.data.Email,
              First_Name: response.data.First_Name,
              Last_Name: response.data.Last_Name,
              Phone: response.data.Phone,
              Title: response.data.Title,
              //  Company is required in Zoho on a Lead and may already be set
              //  as the full name - don't default the value and let the user enter it
              //Company: response.data.Company,
              Company: '',
              Quantity: response?.data?.Quantity ?? 1,
              Description: response?.data?.Description,
              //  Address
              Street: response?.data?.Street ?? "",
              City: response?.data?.City ?? "",
              State: response?.data?.State ?? "",
              Zip_Code: response?.data?.Zip_Code ?? "",
              Country: response?.data?.Country ?? "",
              Special_Requests: response?.data?.Special_Requests ?? "",
            });
            if (response.data.Owner) {
              setOwner(response.data.Owner);
            }
            if (response.data.Product) {
              setProduct(response.data.Product);
            }
          } else if (response.status === 206 && !!response?.data?.message) {
            setSubmitStatus(response.data.message);
          } else if (response.status === 204) {
            setSubmitStatus("ALREADY_SUBMITTED");
          } else {
            setSubmitStatus("NOT_FOUND");
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              setSubmitStatus("ERROR_LOADING");
              break;
            case 404:
              if (error?.data?.message) {
                setSubmitStatus(error.data.message);
              } else {
                setSubmitStatus("NOT_FOUND");
              }
              break;
            case 502:
            case 503:
              setSubmitStatus("PLEASE_WAIT")
              break;
            default:
              setSubmitStatus("ERROR_LOADING")
              break;
          }

          console.error({ error });
        });
    } else {
      setSubmitStatus("ERROR_LOADING");
    }

  }, [code]);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    //// @ts-expect-error ts(2322)  //  Fallback if not compiling
    resolver: yupResolver<IFormInput>(schema),
  });
  const onSubmit = handleSubmit(async (data) => {

    console.log(data);
    //  Submit to Zoho
    //  Swap out to success screen
    //  

    try {
      const axiosPost = await axios({
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        method: "POST",
        url: `${CATALYST_HOST}/server/clientfunction/client/PostBackForm`,
        data
      });

      console.log(axiosPost);

      if (!!axiosPost.data.code && axiosPost.data.code === "SUCCESS") {
        //  Change page to success
        setSubmitStatus(axiosPost.data.code);
      } else {
        //  Fail gracefully
        if (!!axiosPost?.data?.code) {
          setSubmitStatus(axiosPost.data.code);
        } else {
          setSubmitStatus("FAILURE");
        }
      }
    } catch (error: any) {
      console.error('Axios post error => ', error);
      switch (error.response.status) {
        case 400:
          setSubmitStatus("FAILURE");
          break;
        case 404:
          setSubmitStatus("NOT_FOUND");
          break;
        case 502:
        case 503:
          setSubmitStatus("PLEASE_WAIT")
          break;
        default:
          setSubmitStatus("ERROR_LOADING")
          break;
      }
    }
  })

  const phoneIsInvalid = !!errors?.Phone?.message;

  const copy = "Complete the following form to verify your information with us - a member of our team will be in touch with the latest availability and prices as soon as possbile.";

  return (
    <ChakraProvider theme={theme}>
      <Box
        minWidth={'xs'}
        bg={useColorModeValue('#202b49', '#202b49')}
        px={4}
        padding={4}>
        <Center>
          <Logo h={'90px'}></Logo>
        </Center>
      </Box>
      <Container minWidth={'xs'}>
        {CATALYST_HOST.includes('localhost') && <Text>Submit status: {renderResponseBadge(submitStatus ?? 'NOT_SUBMITTED')}</Text>}
        <Flex flexDirection={'column'} textAlign="left">
          <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
            {!!submitStatus && renderResponseBox(submitStatus, owner)}
            {!!!initialValues && !!!submitStatus && <Center>
              <Spinner color='202b49' emptyColor='gray.200' size={"xl"}></Spinner>
            </Center>}
            {!!!submitStatus && !!initialValues &&
              <form onSubmit={onSubmit}>
                <Heading>Tell us more about you</Heading>
                <Text>{copy}</Text>
                <VisuallyHiddenInput
                  id='id'
                  value={initialValues.id}
                  {...register('id', {})}
                />
                <VisuallyHiddenInput
                  id='Client_Code'
                  value={initialValues.Client_Code}
                  {...register('Client_Code', {})}
                />
                {/* <FormControl>
                  <FormLabel htmlFor="Description">Salesperson Notes</FormLabel>
                  <Textarea
                    id="Description"
                    defaultValue={initialValues?.Description}
                    disabled={true}
                    resize={"vertical"}
                  />
                </FormControl> */}
                {/*<Box mt={'5'} p='5' borderWidth='1px' rounded='md'>
                  <Heading size={'xs'} my='2'>
                    Salesperson Notes
                  </Heading>
                  <Text whiteSpace="pre-line" pb='3' mb='3' color={!!initialValues?.Description ? 'black' : 'darkgrey'}>
                    {!!initialValues?.Description ? initialValues.Description : 'Nothing has been entered by our salesperson'}
                  </Text>
                </Box>*/}
                <Box mt={'5'} p='5' borderWidth='1px' rounded='md'>
                  <Text whiteSpace="pre-line" pb='1' mb='1' color={'black'}>
                    <b>Name: </b>{product?.Friendly_Name}
                  </Text>
                  <Text whiteSpace="pre-line" pb='1' mb='1' color={'black'}>
                    <b>Date: </b>{product?.Event_Date}
                  </Text>
                  <Text whiteSpace="pre-line" pb='1' mb='1' color={'black'}>
                    <b>Package: </b>{product?.Package}
                  </Text>
                  <Text whiteSpace="pre-line" pb='1' mb='1' color={'black'}>
                    <b>Venue: </b>{product?.Venue}
                  </Text>
                </Box>
                <HospitalityFormControl
                  id="Quantity"
                  label="Quantity"
                  placeholder=""
                  defaultValue={initialValues?.Quantity}
                  errorMessage={errors?.Quantity?.message}
                  type="number"
                  registerProps={register('Quantity', {
                    required: 'Quantity is required',
                    min: 0,
                    max: 9999,
                  })}
                />
                {/* First Name Start */}
                <HospitalityFormControl
                  id="First_Name"
                  label="First Name"
                  placeholder="First Name"
                  defaultValue={initialValues?.First_Name}
                  errorMessage={errors?.First_Name?.message}
                  registerProps={register('First_Name', {
                    required: 'First Name is required',
                    minLength: { value: 2, message: 'Minimum length should be 2' },
                  })
                  }
                />
                {/* First Name End */}
                {/* Last Name Start */}
                <HospitalityFormControl
                  id="Last_Name"
                  label="Last Name"
                  placeholder="Last Name"
                  defaultValue={initialValues?.Last_Name}
                  errorMessage={errors?.Last_Name?.message}
                  registerProps={register('Last_Name', {
                    required: 'Last Name is required',
                    minLength: { value: 2, message: 'Minimum length should be 2' },
                  })
                  }
                />
                {/* Last Name End */}
                {/* Email Start */}
                <HospitalityFormControl
                  id="Email"
                  label="Email"
                  placeholder="somebody@hospitalitycentre.co.uk"
                  defaultValue={initialValues?.Email}
                  errorMessage={errors?.Email?.message}
                  registerProps={register('Email', {
                    required: 'First Name is required',
                  })
                  }
                />
                {/* Email End */}
                {/* PhoneNumber Start */}
                <FormControl isInvalid={phoneIsInvalid}>
                  <FormLabel htmlFor='Phone'>Phone Number</FormLabel>
                  <Controller
                    control={control}
                    name="Phone"
                    // render={({ field: { onChange, onBlur, value, ref } }) => (
                    render={({
                      field,
                      fieldState
                    }) => (
                      <PhoneNumberInput
                        // onChange={onChange}
                        // onBlur={onBlur}
                        field={field}
                        fieldState={fieldState}
                        defaultValue={initialValues?.Phone}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Phone && errors.Phone.message}
                  </FormErrorMessage>
                </FormControl>
                {/* PhoneNumber End */}
                {/* Company Name Start */}
                <HospitalityFormControl
                  id="Company"
                  label="Company"
                  optional={true}
                  placeholder="Company"
                  defaultValue={initialValues?.Company}
                  errorMessage={errors?.Company?.message}
                  registerProps={register('Company', {

                  })
                  }
                />
                {/* Company Name End */}
                {/* Address Start */}
                <Box mt={'5'} mb={'5'} p='5' borderWidth='1px' rounded='md'>

                  <HospitalityFormControl
                    id="Street"
                    label="Address"
                    placeholder="Street"
                    defaultValue={initialValues?.Street}
                    errorMessage={errors?.Street?.message}
                    registerProps={register('Street', {

                    })
                    }
                  />
                  <HospitalityFormControl
                    id="City"
                    label=""
                    placeholder="City"
                    defaultValue={initialValues?.City}
                    errorMessage={errors?.City?.message}
                    registerProps={register('City', {

                    })
                    }
                  />
                  <HospitalityFormControl
                    id="State"
                    label=""
                    placeholder="State (optional)"
                    defaultValue={initialValues?.State}
                    errorMessage={errors?.State?.message}
                    registerProps={register('State', {

                    })
                    }
                  />
                  <HospitalityFormControl
                    id="Country"
                    label=""
                    placeholder="Country"
                    defaultValue={initialValues?.Country}
                    errorMessage={errors?.Country?.message}
                    registerProps={register('Country', {

                    })
                    }
                  />
                  <HospitalityFormControl
                    id="Zip_Code"
                    label=""
                    placeholder="Zip Code"
                    defaultValue={initialValues?.Zip_Code}
                    errorMessage={errors?.Zip_Code?.message}
                    registerProps={register('Zip_Code', {

                    })
                    }
                  />
                </Box>
                {/* Address End */}
                {/* Special Requests Start */}
                <HospitalityFormControl
                  id="Special_Requests"
                  label="Special Requests"
                  optional={true}
                  placeholder=""
                  type="textarea"
                  defaultValue={initialValues?.Special_Requests}
                  errorMessage={errors?.Special_Requests?.message}
                  registerProps={register('Special_Requests', {

                  })
                  }
                />
                {/* Special Requests End */}
                <Button
                  isLoading={isSubmitting}
                  type='submit'>
                  {"Submit >"}
                </Button>
              </form>
            }
          </Box>
          {/* <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text>
              Edit <Code fontSize="xl">src/App.tsx</Code> and save to reload.
            </Text>
            <Link
              color="teal.500"
              href="https://chakra-ui.com"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn Chakra
            </Link>
          </VStack>
        </Grid> */}
        </Flex>
      </Container>
    </ChakraProvider>
  )
}
