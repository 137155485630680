import * as React from "react"
import {
    Box,
    Text,
    Input,
    InputGroup,
    useDisclosure,
    useOutsideClick,
    InputLeftElement,
    VisuallyHiddenInput
} from "@chakra-ui/react";
import Countries from "./countries.json";
import { AsYouType } from "libphonenumber-js";
import { useState, useEffect, useRef } from "react";
import { Country, SearchOnList } from "./SearchOnList";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { ControllerFieldState, ControllerRenderProps, RefCallBack } from "react-hook-form";
import { IFormInput } from "./types";

export type PhoneNumberInputProps = {
    // value: string;
    // onChange: (arg: string) => void;
    // onBlur: (arg: string) => void;
    field: ControllerRenderProps<IFormInput, "Phone">
    fieldState: ControllerFieldState,
    defaultValue: string | undefined
};


export const PhoneNumberInput = ({ field, fieldState, defaultValue }: PhoneNumberInputProps) => {
    const { onChange, value } = field;
    const { invalid } = fieldState;

    const boxRef = useRef(null);

    const parsedDefaultValue = new AsYouType().input(defaultValue ? defaultValue : "");

    const [number, setNumber] = useState('');
    const [countryDialCode, setCountryDialCode] = useState('');
    const [countryFlag, setCountryFlag] = useState(`🇬🇧`);
    const { isOpen, onToggle, onClose } = useDisclosure();

    useEffect(() => {
        let startingDialCode = '+44';
        let startingPhone = '';
        if (!!parsedDefaultValue) {
            const splitParsedDefaultValue = parsedDefaultValue.split(' ');

            if (!!splitParsedDefaultValue && !!splitParsedDefaultValue[0] && splitParsedDefaultValue[0].startsWith('+')) {
                startingDialCode = splitParsedDefaultValue[0];
                startingPhone = splitParsedDefaultValue.slice(1).join(' ');
            } else {
                startingPhone = parsedDefaultValue;
            }
        }

        console.log("", { startingDialCode, startingPhone });

        setNumber(startingPhone);
        setCountryDialCode(startingDialCode);
    }, []);

    useOutsideClick({
        ref: boxRef,
        handler: () => onClose()
    });

    useEffect(() => {
        //  Set the combined phone number if we have both
        if (!!countryDialCode && !!number) {
            onChange(`${countryDialCode}${number}`);
        } else if (!!!countryDialCode && !!!number) {
            onChange('');
        }
    }, [countryDialCode, number, onChange]);


    // useEffect(() => {
    //     // if (!invalid && !!value) {
    //     //     setNumber(value.split(" ").slice(1).join(" "));
    //     // }
    //     if (!!value) {
    //         setNumber(value.split(" ").slice(1).join(" "));
    //     }
    // }, [invalid, value]);

    const onCountryChange = (item: Country) => {

        setCountryDialCode(item?.dial_code);
        setCountryFlag(item?.flag);
        const parsedNumber = new AsYouType().input(`${countryDialCode}${number}`);
        onChange(parsedNumber);
        onClose();
    };

    const onPhoneNumberChange = (event: any) => {
        const value = event.target.value;
        const parsedNumber = !!value
            ? new AsYouType().input(`${countryDialCode}${value}`)
            : '';

        setNumber(value);
        console.log('onPhoneNumberChange => ', { parsedNumber, value });
        onChange(parsedNumber);
    };

    return (
        <Box as="section" ref={boxRef} position="relative">
            <InputGroup>
                {/* Hide the actual phone number generated and use it to fill in the form */}
                {/* So that we can validate when the number is actually entered */}
                {/* Otherwise we will be validating a potentially empty and optional field */}
                <VisuallyHiddenInput
                    {...field}
                />
                <InputLeftElement width="5em" cursor="pointer" onClick={onToggle}>
                    <Text as="span" mr={3}>
                        {countryDialCode}
                    </Text>
                    {isOpen ? (
                        <ChevronUpIcon boxSize={6} color="gray.500" />
                    ) : (
                        <ChevronDownIcon boxSize={6} color="gray.500" />
                    )}
                </InputLeftElement>
                <Input
                    pl="5em"
                    type="tel"
                    placeholder="Phone Number"
                    onBlur={onPhoneNumberChange}
                    onChange={(event) => setNumber(event.target.value)}
                    value={number}
                />
            </InputGroup>

            {isOpen ? (
                <SearchOnList data={Countries} onChange={onCountryChange} />
            ) : null}
        </Box>
    );
};
